.profil-page {
  margin: 80px auto 0;
  max-width: 1200px;
  display: grid;
  grid-template-columns: 362px 1fr 60px;
  .icons {
    top: 55px;
  }

  .log-container {
    margin: 0 auto;

    @media screen and (max-width: 885px) {
      grid-template-columns: 1fr;
    }
  }

  .connection-form {
    border: none;
    background: $color-4;
    min-width: 490px;
    width: 90%;
    text-align: center;
    margin: 0 auto;
    padding: 20px;
    border-radius: 5px;

    @media screen and (max-width: 550px) {
      min-width: 200px;
    }

    .form-container {
      display: grid;
      grid-template-columns: 30% 70%;

      @media screen and (max-width: 550px) {
        grid-template-columns: 1fr;
      }

      ul {
        li {
          cursor: pointer;
          border-radius: 5px;
          padding: 10px 20px;
          transition: 0.2s;

          @media screen and (max-width: 550px) {
            width: 80%;
            margin: 0 auto;
          }
        }
        .active-btn {
          background: $color-1;

          &:hover {
            background: $color-2;
          }
        }
        li:not(.active-btn) {
          &:hover {
            transform: translateX(3px);
          }
        }
        #login {
          position: relative;
          @media screen and (max-width: 550px) {
            margin-bottom: 30px;

            &:after {
              content: "";
              position: absolute;
              bottom: -10px;
              left: 50%;
              transform: translateX(-50%);
              width: 80%;
              height: 1px;
              background: $color-2;
            }
          }
        }
      }
      form {
        input {
          padding: 14px 20px;
          margin: 0;
          font-size: 1.2rem;
          font-family: $font-2;
          border-radius: 5px;

          &:focus {
            box-shadow: 0 0 3px rgba(51, 51, 51, 0.2);
          }
        }
        a {
          color: rgb(62, 62, 214);
        }
      }
    }
  }
  h1 {
    text-align: center;
    margin: 10px 0 6px;
  }
  .img-container {
    display: grid;
    align-items: center;
    height: 100%;

    img {
      height: 280px;

      @media screen and (max-width: 1050px) {
        height: 220px;
      }
      @media screen and (max-width: 950px) {
        height: 200px;
      }
      @media screen and (max-width: 885px) {
        display: none;
      }
    }
  }
  .update-container {
    display: grid;
    grid-template-columns: 30% 70%;
    background: $color-4;
    border-radius: 5px;
    margin: 20px 10px;

    @media screen and (max-width: 700px) {
      grid-template-columns: 1fr;
    }

    > div {
      padding: 25px;
      margin: 20px 10px;

      @media screen and (max-width: 700px) {
        margin: 10px 10px;
      }
    }
    .left-part {
      text-align: center;

      h3 {
        margin-bottom: 12px;
      }

      img {
        height: 180px;
        width: 180px;
        border-radius: 5px;
        object-fit: cover;
        margin-bottom: 30px;
        box-shadow: 0 0 6px rgba(51, 51, 51, 0.363);
      }
      form {
        position: relative;

        label {
          width: 185px;
          background: $color-3;
          color: $color-2;
          border-radius: 5px;
          display: inline-block;
          padding: 9px 0;
          transition: 0.2s;
          z-index: 10;
          cursor: pointer;
        }
        .file-path{
          padding: 9px 0px;    
          margin-top: 10px;
          background: rgb(253, 253, 253);
          border-radius: 5px;
          width: 270px;
        }
        input[type="file"] {
          display: none;
        }
        input[type="submit"] {
          margin-top: 10px;
          border-radius: 5px;
          width: 185px;
          &:hover {
            letter-spacing: 1px;
          }
        }
      }
      p {
        color: red;
        font-family: $font-3;
        margin-top: 6px;
      }
    }
    .right-part {
      text-align: center;
      padding: 20px 40px;
      h3 {
        margin-bottom: 12px;
      }
      p,
      textarea {
        min-height: 80px;
        border: 1px solid $color-1;
        border-radius: 5px;
        padding: 10px 20px;
        font-size: 1.1rem;
      }
      textarea {
        width: 100%;
        margin-bottom: -6px;
        font-size: 1.2rem;
      }
      button {
        margin: 5px 0;
        float: right;
      }
      h4 {
        margin: 60px 0 10px;
      }
      h5 {
        background: $color-1;
        color: $color-4;
        font-family: $font-1;
        margin: 20px 0;
        padding: 12px;
        font-size: 1.1rem;
        border-radius: 5px;
        transition: 0.2s;
        cursor: pointer;

        &:hover {
          filter: brightness(110%);
          transform: translateX(2px);
        }
      }
    }
  }
  .popup-profil-container {
    z-index: 100;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    position: fixed;
    animation: popup 1s forwards;

    @keyframes popup {
      to {
        backdrop-filter: blur(2px);
      }
    }

    .modal {
      position: absolute;
      top: 10%;
      left: 50%;
      transform: translate(-100%);
      background: $color-2;
      padding: 25px;
      border-radius: 5px;
      box-shadow: 0 0 2px rgba(131, 130, 130, 0.356);
      overflow: auto;
      transform: scaleY(0);
      transform-origin: center;
      animation: modal .5s forwards;

      h3 {
        min-width: 200px;
      }

      @keyframes modal {
        to {
          transform: scale(1) translate(-50%);
        }
      }

      .cross {
        position: absolute;
        top: 25px;
        right: 25px;
        transition: 0.1s;
        cursor: pointer;

        &:hover {
          transform: scale(1.07);
        }
      }

      ul {
        margin: 20px 0 0;
        max-height: 500px;
        overflow-y: scroll;
        overflow-x: hidden;

        &::-webkit-scrollbar {
          width: 2px;
        }
        &::-webkit-scrollbar-track {
          background: $color-4;
        }
        &::-webkit-scrollbar-thumb {
          background: $color-1;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }

        li {
          display: grid;
          grid-template-columns: 64px 1fr 114px;
          align-items: center;
          margin: 10px 0;

          img {
            height: 50px;
            width: 50px;
            border-radius: 5px;
            object-fit: cover;
            box-shadow: 0 0 2px rgba(51, 51, 51, 0.376);
          }
          h4 {
            text-align: left;
            min-width: 210px;
          }
          .follow-handler {
            text-align: left;

            button {
              background: $color-4;
              border-radius: 5px;
              margin-right: 6px;

              &:hover {
                color: $color-3;
                transform: translateX(2px);
              }
            }
          }
        }
      }
    }
  }
}
