.user-page{
    margin: 58px auto 0;
    max-width: 1200px;
    display: grid;
    grid-template-columns: 1fr 60px;
    .user-container{
        background: #ecdff5;
        border-radius: 5px;
        margin: 20px 10px;
        display: grid;
        grid-template-columns: 30% 70%;
        .user-left{
            margin: 10px;
            padding: 10px;
            text-align: center;
            h3{
                margin-bottom: 12px;
            }
            img{
                height: 180px;
                width: 180px;
                border-radius: 5px;
                object-fit: cover;
                box-shadow: 0 0 6px rgb(51 51 51 / 36%);
            }
        }
        .user-right {
            margin: 10px;
            padding: 10px;
            .column{
                display: flex;
                flex-direction: column;
                text-align: center;
            }
            .user-infos{
                margin: 20px;
            }
            .user-stats{
                margin: 20px;
                display: grid;
                grid-template-columns: 50% 50%;
            }
            h3{
                text-align: center;
                margin-bottom: 12px;
            }
        }
    }
    .user-posts{
        h3{
            text-align: center;
            margin-bottom: 12px;
        }
    }
}