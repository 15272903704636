.filtered-thread{
    display: grid;
    max-width: 1200px;
    grid-template-columns: 374px 1fr 60px;
    overflow: hidden;
    margin: 58px auto 50px;
    position: relative;
    .thread-header{
        margin: 0 auto 12px;
        text-align: center;
    }
}